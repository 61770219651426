import React from 'react';
import LambdaFetch from '../functions/FetchFromLambda';
import DialogTable from './DialogTable';
import getTableOptions from '../constants/TableOptions';
import { perActHistModalCols, perActCommentModalCols } from '../constants/EmployeeCols';
import ReactTable from './ReactTable';
import { usePerActHistCols } from '../hooks/EmployeeCols';
import fetchFromAthena from '../functions/FetchFromAthena'


export default function PersonalActHisotry(props) {
  const perActHistCols = usePerActHistCols();
  const [objInfo, setObjInfo] = React.useState(null);
  const [commentInfo, setCommentInfo] = React.useState(null)
 

  const getPerAction = async (objId) => {
    const resp = await LambdaFetch(
      'employee-form',
      'post',
      props.accessToken,
      JSON.stringify({
        action: 'per_act',
        objId,
        empId: props.empId,
        company: props.company,
      }),
      '',
      props.fetchInitialData.credentials
    );

    if (resp.success) {
      setObjInfo(resp.data.personalActDetail);
    } else {
      setObjInfo(null);
    }
  };

  const getComments = async (row) => {
    const data = await fetchFromAthena({
      queryName: 'employee_action_comments',
      headers: {
        company: props.company,
        employee: props.empId,
        action: row.ACTION_CODE,
        effectDate: row.EFFECT_DATE
      },
      accessToken: props.fetchInitialData.credentials.user.accessToken,
      credentials: props.fetchInitialData.credentials
    })

    setCommentInfo(data)
  }

  const tableData = props.data.map((row,index) => {
    return {
      ...row,
      ACTION_CODE_LINK: (
        <div key={index} className="editLink" onClick={() => getPerAction(row.OBJ_ID)}>
          {row.ACTION_CODE}
        </div>
      ),
      COMMENT_LINK: row.COMMENT_COUNT > 0 && (
        <div key={index} className="editLink" onClick={() => getComments(row)}>
          view
        </div>
      )
    };
  });
  return (
    <div>
      {commentInfo && (
      <DialogTable
        isOpen={!!commentInfo}
        handleClose={() => setCommentInfo(null)}
        tableData={commentInfo}
        cols={perActCommentModalCols}
        options={getTableOptions({
          cols: perActCommentModalCols,
          fileName: 'comments',
        })}
      />
    )}

      {objInfo && (
        <DialogTable
        isOpen={!!objInfo}
        handleClose={() => setObjInfo(null)}
        tableData={objInfo}
        cols={perActHistModalCols}
        options={getTableOptions({
          cols: perActHistModalCols,
          fileName: 'actions',
        })}
        />
      )}

      <ReactTable cols={perActHistCols} data={tableData} />

    </div>
  );
}
