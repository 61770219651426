import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  IconButton,
  Typography,
  Grid,
  TextField,
  Paper,
  Button
} from '@material-ui/core'
import Autocomplete from '@mui/material/Autocomplete'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import LambdaFetch from '../functions/FetchFromLambda'
import LoadingButton from '../components/LoadingButton'
import {
  trialBalanceConsolidatedCols,
  glTransServerSideCols2
} from '../constants/GlCols'
import { smallScreenWidth } from '../constants/AppConstants'
import ReactTable from '../components/ReactTable'
import AutocompleteDropDown from '../components/AutocompleteDropdown'
import TableViewModal from '../components/TableViewModal'
import fetchFromAthena from '../functions/FetchFromAthena'
import { DashboardAthenaTableServerSide } from '../components/DashboardAthenaTableServerSide'
import ServerSideAutoCompletev2 from '../functions/ServerSideAutoCompletev2';


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: '2rem 1rem'
  },
  footerCell: {
    backgroundColor: '#F5F5F5',
    borderBottom: 'none',
    zIndex: 999
  },
  stickyFooterCell: {
    position: 'sticky',
    bottom: '-1px',
    zIndex: 100
  },
  rightTableHead: {
    '& > span': {
      justifyContent: 'flex-end',
      marginRight: '0'
    }
  }
}))

const transTypes = [
  {
    id: 'A',
    description: 'Amount'
  },
  {
    id: 'U',
    description: 'Unit'
  },
  {
    id: 'B',
    description: 'Budget'
  },
  {
    id: 'C',
    description: 'Currency'
  },
  {
    id: 'N',
    description: 'Budget Unit'
  },
  {
    id: 'Y',
    description: 'Currency One'
  },
  {
    id: 'Z',
    description: 'Currency Two'
  }
]
const defaultHeaders = {
  fiscalYear: '',
  companyGroup: '',
  company: '',
  period: '',
  type: 'A'
}

export default function TrialBalanceConsolidatedByCompanyGroup(props) {
  const [state, setstate] = useState({
    options: null,
    isLoading: false,
    data: null,
    fetched: false
  })
  const [headers, setHeaders] = useState(defaultHeaders)
  const [showTable, setShowTable] = useState(false)
  const [clearHeader, setClearHeaders] = React.useState(false)

  const classes = useStyles()

  const { credentials } = props.fetchInitialData

  const handleSubmit = async e => {
    e.preventDefault()
    try {

      if (!headers.company && !headers.companyGroup) {
        props.createSnack('Company Group or Company must be selected', "warning", 3000)
        return
      }
      setstate({ ...state, isLoading: true })
      const currentType = transTypes.find(
        option => option.description === e.target.type.value
      ).id


      const data = await fetchFromAthena({
        queryName: 'gl_trial_balance_consolidated_by_company_group',
        headers,
        accessToken: credentials.user.accessToken,
        credentials: credentials
      })

      const trialBalanceData = data
      setstate({
        ...state,
        data: trialBalanceData.map(row => {
          return {
            ...row,
            GL_TRANS_VIEW: (
              <TableViewModal
                fetchData={getGlTransData}
                children={
                  <DashboardAthenaTableServerSide
                    {...props}
                    params={{
                      company: row.COMPANY,
                      fiscalYear: headers.fiscalYear,
                      fromPeriod: headers.period,
                      toPeriod: headers.period,
                      account: row.ACCOUNT,
                      subAccount: row.SUB_ACCOUNT
                    }}
                    tableColumns={glTransServerSideCols2}
                    reportName='gl_trans_read_serverside_v3'
                    tableTitle={''}
                    callback={() => { }}

                    transform={tableDataTransformation}
                  />

                }
              />
            )
          }
        }),
        isLoading: false
      })
    } catch (e) {
      console.log(e)
    }
  }

  const tableDataTransformation = rows => {
    return rows.map(row => {
      if (row.ACTIVITY) {
        row['ACTIVITY_LINK'] = (
          <div
            className='editLink'
            onClick={() => {
              const win = window.open(
                `/actrans?id=${row.OBJ_ID}&type=GLT_OBJ_ID`,
                '_blank'
              )
              win.focus()
            }}
          >
            {row.ACTIVITY}
          </div>
        )
      } else {
        row['ACTIVITY_LINK'] = ''
      }
      let temp = row.ACCOUNT
      row.ACCOUNT = `${temp}${row.SUB_ACCOUNT ? `-${row.SUB_ACCOUNT}` : ''}`
      row['SYSTEM_LINK'] = (
        <div>
          {row.SYSTEM}
        </div>
      )
      return row
    })
  }

  const getGlTransData = async params => {
    try {
      const resp = await LambdaFetch(
        'gl-trans',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'get-trans',
          ...params
        }),
        '',
        credentials
      )
    } catch (error) {
      return false
    }
  }
  const handleCallback = (values, error) => {
    if (error) {
      setstate({ ...state, isLoading: false })
      setShowTable(false)
      return null
    }
    setstate({ ...state, isLoading: false })
  }

  return (
    <div style={{ padding: '1.5rem 0.5rem' }}>
      <IconButton
        style={{ borderRadius: '5px', position: 'absolute' }}
        size='small'
        onClick={() => props.history.push('/')}
        aria-label='delete'
      >
        <ArrowBackIcon />
        {props.fetchInitialData.credentials.appWidth > smallScreenWidth &&
          'Dashboard'}
      </IconButton>
      <Typography variant='h5' style={{ textAlign: 'center' }}>
        GL Trial Balances Consolidated
      </Typography>
      <div className={classes.root}>
        <Paper elevation={0} style={{ padding: '1rem' }}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              alignContent='center'
              alignItems='flex-end'
              spacing={3}
            >


              <Grid key={"companyGroup"} item>
                <ServerSideAutoCompletev2
                  required={false}
                  id={"companyGroup"}
                  width="150px"
                  label={"Company Group"}
                  key={clearHeader}
                  dontAutoHighlight
                  dontOpenOnFocus
                  accessToken={credentials.user.accessToken}
                  error={`No Company Group Found`}
                  variant="standard"
                  handleChange={(id, hit) => {
                    setHeaders({ ...headers, ["companyGroup"]: id });
                  }}
                  credentials={credentials}
                  apiResource="trial-balance"
                  secondaryParams={{
                    lookupField: "COMPANY_GROUP",
                    action: 'autocomplete_gl_company_by_group',
                    ...headers,
                  }}
                />
              </Grid>

              <span style={{ marginBottom: "1rem" }}>OR</span>

              <Grid key={"company"} item>
                <ServerSideAutoCompletev2
                  required={false}
                  id={"company"}
                  width="150px"
                  label={"Company"}
                  key={clearHeader}
                  dontAutoHighlight
                  dontOpenOnFocus
                  accessToken={credentials.user.accessToken}
                  error={`No Company Found`}
                  variant="standard"
                  handleChange={(id, hit) => {
                    setHeaders({ ...headers, ["company"]: id });
                  }}
                  credentials={credentials}
                  apiResource="trial-balance"
                  secondaryParams={{
                    lookupField: "COMPANY",
                    action: 'autocomplete_gl_company_by_group',
                    ...headers,
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              alignContent='center'
              alignItems='flex-end'
              spacing={3}
            >
              <Grid item>
                <AutocompleteDropDown
                  {...props}
                  id={'fiscalYear'}
                  name={'Year'}
                  width='150px'
                  key={clearHeader}
                  required={true}
                  variant='standard'
                  label={'Year'}
                  value={headers.fiscalYear}
                  category={'fiscal-year'}
                  screen={'trial-balances'}
                  onChange={val =>
                    setHeaders({
                      ...headers,
                      fiscalYear: val ? val.key_value : ''
                    })
                  }
                />
              </Grid>
              <Grid item>
                <AutocompleteDropDown
                  {...props}
                  id={'period'}
                  name={'period'}
                  width='150px'
                  key={clearHeader}
                  required={true}
                  variant='standard'
                  label={'Period'}
                  value={headers.period}
                  category={'period'}
                  screen={'trial-balances'}
                  onChange={val =>
                    setHeaders({
                      ...headers,
                      period: val ? val.key_value : ''
                    })
                  }
                />
              </Grid>
              <Grid item>
                <Autocomplete
                  id='type'
                  options={transTypes}
                  getOptionLabel={option => option.description}
                  defaultValue={transTypes[0]}
                  style={{ width: '150px' }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant='standard'
                      margin='dense'
                      label='Type'
                    />
                  )}
                />
              </Grid>
            </Grid>

            <div style={{ marginTop: '1rem' }}>
              <LoadingButton
                label='Submit'
                isLoading={state.isLoading}
                color='primaryVLButton'
                buttonType='submit'
              />
              {!!(headers.fiscalYear || headers.period || headers.company) && (
                <Button
                  onClick={() => {
                    setClearHeaders(!clearHeader)
                    setHeaders(defaultHeaders)
                  }}
                  style={{ marginLeft: '0.5rem' }}
                >
                  {' '}
                  Clear
                </Button>
              )}
            </div>
          </form>
        </Paper>

        {state.data && (
          <Grid
            item
            xs={12}
            style={{ position: 'relative', marginTop: '1rem' }}
          >
            <ReactTable cols={trialBalanceConsolidatedCols} data={state.data} />
          </Grid>
        )}
      </div>
    </div>
  )
}
